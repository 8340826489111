export enum TransferRequestCondition {
  OCD = "OCD",
  HOARDING_DISORDER = "Hoarding Disorder",
  BFRBS = "BFRBs",
  PTSD = "PTSD",
  BDD = "BDD",
  DEPRESSION = "Depression",
  ANXIETY_DISORDER = "Anxiety Disorder",
}

export enum RequestTypes {
  ADD = "add",
  REPLACE = "replace",
}

export interface TransferRequestNote {
  id: number;
  transfer_request_id: number;
  note: string;
  created_at: string;
  created_by: string;
}

interface TransferRequestMember {
  user_id: number;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  dob: string | null;
  state: string | null;
  country_code: string | null;
  current_scheduling_frequency: string | null;
  timezone: string | null;
}

export enum TransferRequestStatus {
  NOT_STARTED = "not_started",
  ASSIGNED = "assigned",
  CONTACT_1 = "contact_1",
  CONTACT_2 = "contact_2",
  CONTACT_3 = "contact_3",
  APPT_OFFERED = "appt_offered",
  COMPLETED = "completed",
  MEMBER_DECLINED = "member_declined",
  UNRESPONSIVE = "unresponsive",
  CANCELLED = "cancelled",
  MANUAL_REACH_OUT = "manual_reach_out",
  WAITLIST = "waitlist",
  WAITLIST_SPECIALTY_PROVIDER = "waitlist_specialty_provider",
  WAITLIST_AGE = "waitlist_age",
  WAITLIST_INSURANCE = "waitlist_insurance",
  WAITLIST_STATE_COUNTRY = "waitlist_state_country",
  PENDING = "pending",
}

interface TransferRequestClinician {
  user_id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  region: string | null;
  manager_email: string | null;
  current_status: string | null;
  avatar_url: string | null;
  timezone: string | null;
}

export interface TransferCommunicationAutomation {
  id: number;
  transfer_request_id: number;
  user_id: number;
  status: string;
  last_notification: string;
  last_notification_sent_at: string;
  updated_at: string;
  created_at: string;
  started_at: string;
  started_by: string;
  deleted_at: string;
  deleted_by: string;
  stopped_at: string;
  stopped_by: string;
}

export interface TransferRequest {
  id: number;
  user_id: number;
  reason: string;
  reason_details: string;
  conditions: string[];
  clinician_transferring_from_email: string;
  is_current_clinician_continuing_treatment: boolean;
  current_clinician_treatment_end_date: string | null;
  clinician_transferred_to_email: string | null;
  bootstrap_log_id: number | null;
  status: TransferRequestStatus;
  action: "replace" | "add";
  assigned_to: string | null;
  created_by: string;
  created_at: string;
  updated_at: string | null;
  updated_by: string | null;
  notes: TransferRequestNote[];
  member: TransferRequestMember;
  clinician_transferring_from: TransferRequestClinician;
  is_read_only: boolean;
  automation_status: string | null;
  automation: TransferCommunicationAutomation | null;
  primary_clinician_state: string | null;
  secondary_clinician_state: string | null;
  current_conditions: string[] | null;
  primary_day_availability: string[] | null;
  co_therapy_duration: string | null;
}
